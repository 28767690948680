import "./App.css";

import firebase from "firebase/compat/app";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import RedeemIcon from "@mui/icons-material/Redeem";

export default function BlasterLinks(props) {
  const db = firebase.firestore();
  const date = new Date();
  const increment = firebase.firestore.FieldValue.increment(3);
  const trendingRef = db
    .collection("trending")
    .doc(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );

  var storePriceData = "";
  var kitPriceData = "";
  var filesPriceData = "";

  if (
    props.hit.storePrice === 0 ||
    props.hit.storePrice === "0" ||
    props.hit.storePrice === ""
  ) {
    storePriceData = "Free";
  } else {
    storePriceData = "$" + props.hit.storePrice;
  }
  if (
    props.hit.kitPrice === 0 ||
    props.hit.kitPrice === "0" ||
    props.hit.kitPrice === ""
  ) {
    kitPriceData = "Free";
  } else {
    kitPriceData = "$" + props.hit.kitPrice;
  }
  if (
    props.hit.filesPrice === 0 ||
    props.hit.filesPrice === "0" ||
    props.hit.filesPrice === ""
  ) {
    filesPriceData = "Free";
  } else {
    filesPriceData = "$" + props.hit.filesPrice;
  }

  const linkClicked = (event, value) => {
    trendingRef.set({ [props.hit.id]: increment }, { merge: true });
  };

  return (
    <div
      style={{
        gridColumn: "links",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.hit.store ? (
        <Tooltip
          title={
            <h2
              style={{
                margin: "0px",
              }}
            >
              {storePriceData}
            </h2>
          }
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <Link
            href={props.hit.store}
            target="_blank"
            onMouseDown={(e) => linkClicked(e, "store")}
          >
            <Avatar
              sx={{
                width: 30,
                height: 30,
                bgcolor: "#3064ad",
                margin: "4px",
              }}
            >
              <RedeemIcon
                sx={{
                  width: "22px",
                }}
              />
            </Avatar>
          </Link>
        </Tooltip>
      ) : (
        ""
      )}

      {props.hit.kit ? (
        <Tooltip
          title={
            <h2
              style={{
                margin: "0px",
              }}
            >
              {kitPriceData}
            </h2>
          }
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <Link
            href={props.hit.kit}
            target="_blank"
            onMouseDown={(e) => linkClicked(e, "kit")}
          >
            <Avatar
              sx={{
                width: 30,
                height: 30,
                bgcolor: "#3064ad",
                margin: "4px",
              }}
            >
              <HomeRepairServiceIcon
                sx={{
                  width: "22px",
                }}
              />
            </Avatar>
          </Link>
        </Tooltip>
      ) : (
        ""
      )}

      {props.hit.files ? (
        <Tooltip
          title={
            <h2
              style={{
                margin: "0px",
              }}
            >
              {filesPriceData}
            </h2>
          }
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <Link
            href={props.hit.files}
            target="_blank"
            onMouseDown={(e) => linkClicked(e, "files")}
          >
            <Avatar
              sx={{
                width: 30,
                height: 30,
                bgcolor: "#3064ad",
                margin: "4px",
              }}
            >
              <InsertDriveFileIcon
                sx={{
                  width: "22px",
                }}
              />
            </Avatar>
          </Link>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
}
