import { useState, useEffect } from "react";
import "../App.css";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardContent";
import "firebase/compat/firestore";
import BlasterActions from "../BlasterActions";
import BlasterLinks from "../BlasterLinks";
import { storage } from "../useFirebase";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import caliburnIconSmall from "../img/caliburnIconSmall.png";
import { ReactFitty } from "react-fitty";

export default function AddBlasterCard(props) {

  console.log(props.blasterData)
  const [cardImg, setCardImg] = useState(caliburnIconSmall);

  useEffect(() => {
    console.log(props.blasterData)
    const getData = async () => {

      const resizedRef = storageRef(
        storage,
        `blasterImages/${props.blasterData.imageArray[0]}_422x238`
      );
      getDownloadURL(resizedRef).then((url) => {
        console.log(url)
        setCardImg(url);
      });
    };
    getData();
    
  }, [props.blasterData, props.source]);



  return (
    <Card className="blasterCardWrapper">
      <CardActionArea
      >
        <div style={{ height: "183px", overflow: "hidden" }}>
          {cardImg === undefined ? (
            <div className="loaderBox">
              <div className="loader"></div>
            </div>
          ) : (
            <CardMedia
              component="img"
              alt="Blaster Icon"
              src={cardImg}
              sx={{ height: "183px" }}
              className="blasterCardMedia"
            />
          )}
        </div>

        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
            // component={ReactFitty}
            sx={{
              marginBottom: "-5px",
              fontWeight: "bold",
              textSizeAdjust: "auto",
              height:"37px",
              lineHeight: "37px",
              verticalAlign: "center"
            }}
          >
            <ReactFitty maxSize={30}>{props.blasterData.blasterName}</ReactFitty>
            
          </Typography>
          <Typography
            sx={{ marginBottom: "-5px", fontWeight: "bold", fontSize: "14px" }}
          >
            {props.blasterData.shortDesc}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ marginTop: "15px" }}
          >
            By: {props.blasterData.creator}
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ marginTop: "15px" }}
          >
            <DescriptionGenerator blasterData={props.blasterData} />
          </Typography>
          <div style={{ position: "absolute", right: "12px", bottom: "0px" }}>
            <BlasterLinks hit={props.blasterData} />
          </div>
          {/* <BlasterLinks hit={hit}></BlasterLinks> */}
        </CardContent>
      </CardActionArea>

      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <BlasterActions blasterData={props.blasterData} />
      </CardActions>
    </Card>
  );
}

function DescriptionGenerator(props) {
  // Half and Full Lenght Formating
  var halfFull = "";



  if (props.blasterData.ammo) {
    const ammoCount =
    props.blasterData.ammo.half +
    props.blasterData.ammo.full +
    props.blasterData.ammo.rival +
    props.blasterData.ammo.megaXL +
    props.blasterData.ammo.mega +
    props.blasterData.ammo.rockets;


    if (props.blasterData.ammo.half) {
      halfFull += "Half";
    }

    if (props.blasterData.ammo.half && props.blasterData.ammo.full) {
      halfFull += " and ";
    }

    if (props.blasterData.ammo.full) {
      halfFull += "Full";
    }

    halfFull += " Length " + props.blasterData.propulsion;

    if (props.blasterData.ammo.rival) {
      halfFull = "Rival " + props.blasterData.propulsion;
    }

    if (props.blasterData.ammo.mega) {
      halfFull = "Mega " + props.blasterData.propulsion;
    }

    if (props.blasterData.ammo.rockets) {
      halfFull = "Rocket " + props.blasterData.propulsion;
    }

    if (props.blasterData.ammo.megaxl) {
      halfFull = "Mega XL " + props.blasterData.propulsion;
    }

    if (ammoCount >= 3){
      halfFull = " Multi Ammo " + props.blasterData.propulsion;
    }
  }

  var fpsStr = "";
  if (props.blasterData.fpsLow) {
    fpsStr += props.blasterData.fpsLow;
  }
  if (
    props.blasterData.fpsHigh &&
    props.blasterData.fpsHigh !== props.blasterData.fpsLow
  ) {
    fpsStr += "-" + props.blasterData.fpsHigh;
  }
  fpsStr += " ";

  return (
    <>
      {halfFull}
      <br />
      {props.blasterData.rof}
      <br />
      {fpsStr}
      FPS
    </>
  );
}
