import React, { useState, useEffect } from "react";
import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { NavLink } from "react-router-dom";
import { setDoc } from "firebase/firestore";
import { SearchClient as TypesenseSearchClient } from "typesense";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LogoutIcon from "@mui/icons-material/Logout";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { storage } from "./useFirebase";
import { getDownloadURL, ref as storageRef } from "firebase/storage";

import AddCircleIcon from "@mui/icons-material/AddCircle";

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();

export default function Header(props) {
  const [user] = useAuthState(auth);
  const [userRole, setUserRole] = useState("User");
  const [searchFix, setSearchFix] = useState(false);
  const [searchList, setSearchList] = useState([]);
  let navigate = useNavigate();

  function changeRole(role) {
    setUserRole(role);
  }

  function handleUser(value) {
    props.onChange(value);
    props.passRole(userRole);
  }

  const [open, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRefSearch = React.useRef(null);
  const [pendingCount, setPendingCount] = useState(0);
  const [searchParameters, setSearchParameters] = useState({
    q: "*",
    query_by: "blasterName,creator,shortDesc,propulsion,rof,desc",
    sort_by: "released:desc",
    per_page: 4,
    infix: "always",
    filterArray: {
      propulsion: [],
      rof: [],
      feed: [],
      construction: [],
      diff: [],
      ammo: [],
      avalibility: [],
      position: [
        "position:Modding Platform",
        "position:High-End",
        "position:Community Developed",
      ],
    },
    filter_by:
      "position:Modding Platform||position:High-End||position:Community Developed",
    page: 1,
  });

  useEffect(() => {
    let client = new TypesenseSearchClient({
      nodes: [
        {
          host: process.env.REACT_APP_TYPESENSE_NODE, // For Typesense Cloud use xxx.a1.typesense.net
          port: "443", // For Typesense Cloud use 443
          protocol: "https", // For Typesense Cloud use https
        },
      ],
      apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_KEY,
      connectionTimeoutSeconds: 2,
    });

    client
      .collections("blasters")
      .documents()
      .search(searchParameters)
      .then(({ hits, found }) => {
        setSearchList(hits);
      });
  }, [searchParameters]);

  const searchChange = (event) => {
    let updatedValue = { q: event.target.value };
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      ...updatedValue,
    }));
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSearch = () => {
    if (searchFix) {
      setSearchFix(false);
      return;
    }
    console.log(prevSearchOpen);
    // setOpenSearch((prevSearchOpen) => !prevSearchOpen.current);

    setOpenSearch(!openSearch);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSearchClose = (event) => {
    if (
      anchorRefSearch.current &&
      anchorRefSearch.current.contains(event.target)
    ) {
      return;
    }

    setOpenSearch(false);
  };

  const navigateSearch = (searchTerm) => {
    setOpenSearch(false);
    setSearchFix(true);
    navigate("/search?searchTerm=" + searchTerm);

    // Reset search box
    setSearchParameters((searchParameters) => ({
      ...searchParameters,
      ...{ q: "*" },
    }));
  };

  useEffect(() => {
    let client = new TypesenseSearchClient({
      nodes: [
        {
          host: process.env.REACT_APP_TYPESENSE_NODE, // For Typesense Cloud use xxx.a1.typesense.net
          port: "443", // For Typesense Cloud use 443
          protocol: "https", // For Typesense Cloud use https
        },
      ],
      apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_KEY,
      connectionTimeoutSeconds: 2,
    });

    client
      .collections("pendingBlasters")
      .documents()
      .search({
        q: "*",
        query_by: "blasterName,creator,shortDesc,propulsion,rof",
        sort_by: "released:desc",
        per_page: 20,
        infix: "always",
        filterArray: {
          propulsion: [],
          rof: [],
          feed: [],
          construction: [],
          diff: [],
          ammo: [],
          avalibility: [],
          position: [],
        },
        page: 1,
      })
      .then(({ found }) => {
        setPendingCount(found);
      });
  }, []);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
      setOpenSearch(false);
    } else if (event.key === "Escape") {
      setOpen(false);
      setOpenSearch(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const prevSearchOpen = React.useRef(openSearch);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (prevSearchOpen.current === true && openSearch === false) {
      anchorRefSearch.current.focus();
    }

    prevSearchOpen.current = openSearch;
  }, [openSearch]);

  React.useEffect(() => {
    if (document.getElementById("headerSearchCard"))
      document.getElementById("headerSearchCard").style.height =
        84 + searchList.length * 84 + "px";
  }, [openSearch, searchList]);

  return (
    <header className="App-header">
      <div style={{ display: "flex" }}>
        <NavLink className="title" to="/">
          BlasterBrowser
        </NavLink>
        <Button
          ref={anchorRefSearch}
          aria-haspopup="true"
          onClick={handleSearch}
          variant="contained"
          className="menuIcon"
          sx={{ ml: "10px" }}
          id="headerSearchButton"
        >
          <SearchIcon
            sx={{
              color: "white",
              width: "36px",
              height: "36px",
              alignSelf: "center",
            }}
          />
        </Button>
      </div>

      <Popper
        open={openSearch}
        anchorEl={anchorRefSearch.current}
        role={undefined}
        placement="right-end"
        transition
        disablePortal
        className="headerSearchPopper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "right-start" ? "left top" : "right top",
            }}
          >
            <Card id="headerSearchCard">
              <ClickAwayListener onClickAway={handleSearchClose}>
                <div onKeyDown={handleListKeyDown}>
                  <TextField
                    autoFocus
                    id="outlined-required"
                    label="Search for Blasters"
                    onChange={searchChange}
                    name="shortDesc"
                    variant="outlined"
                    defaultValue={
                      searchParameters.q === "*" ? "" : searchParameters.q
                    }
                    sx={{ fontcolor: "white", width: "98%", margin: "8px 1%" }}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? navigateSearch(e.target.value) : null
                    }
                  />
                  <div className="searchHeaderCard">
                    {searchList.map((obj) => (
                      <BlasterDetailHeader
                        blaster={obj.document}
                        key={obj.document.id}
                      />
                    ))}
                  </div>
                </div>
              </ClickAwayListener>
            </Card>
          </Grow>
        )}
      </Popper>

      <div className="sideHead">
        <section className="profile">
          {user ? (
            <>
              <Profile />
              <CheckForUser onChange={handleUser} changeRole={changeRole} />
            </>
          ) : (
            <SignIn />
          )}
        </section>
        <div className="menu">
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            variant="contained"
            className="menuIcon"
          >
            <MenuIcon />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: 100 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "right top",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem className="menuItem">
                        <Button
                          href="/search"
                          onClick={handleClose}
                          size="small"
                          className="menuButton"
                        >
                          <SearchIcon />
                          Search
                        </Button>
                      </MenuItem>
                      <MenuItem className="menuItem">
                        <Button
                          href="/add"
                          onClick={handleClose}
                          size="small"
                          className="menuButton"
                        >
                          <AddCircleIcon />
                          Add a Blaster
                        </Button>
                      </MenuItem>
                      {userRole === "Admin" || userRole === "Moderator" ? (
                        <MenuItem className="menuItem">
                          <Button
                            href="/searchPending"
                            onClick={handleClose}
                            size="small"
                            className="menuButton"
                          >
                            <Badge badgeContent={pendingCount} color="success">
                              <PendingActionsIcon />
                            </Badge>
                            Pending Blasters
                          </Button>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      <MenuItem className="menuItem">
                        <Button
                          href="/features"
                          onClick={handleClose}
                          size="small"
                          className="menuButton"
                        >
                          <FeedbackIcon />
                          Request a Feature!
                        </Button>
                      </MenuItem>
                      {auth.currentUser ? (
                        <MenuItem className="menuItem">
                          <Button
                            onClick={() => {
                              auth.signOut();
                              window.location.reload();
                            }}
                            className="menuButton"
                          >
                            <LogoutIcon />
                            Sign Out
                          </Button>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </header>
  );
}

function SignIn(props) {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <Button variant="contained" onClick={signInWithGoogle}>
      Sign in
    </Button>
  );
}

function Profile() {
  const { photoURL } = auth.currentUser;
  return (
    auth.currentUser && (
      <>
        <Link href="./profile">
          <Avatar
            src={
              photoURL ||
              "https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg"
            }
          />
        </Link>
      </>
    )
  );
}

function CheckForUser(props) {
  const { uid, email } = auth.currentUser;

  const db = firebase.firestore();
  var docRef = db.collection("users").doc(uid);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        props.onChange(uid);
        props.changeRole(doc.data()["role"]);
      } else {
        // doc.data() will be undefined in this case
        props.onChange(uid);
        setDoc(docRef, {
          collected: [],
          wishlist: [],
          username: email,
          role: "User",
        });
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
}

function BlasterDetailHeader({ blaster }) {
  const [blasterHero, setBlasterHero] = useState();

  const db = firebase.firestore();
  const date = new Date();
  const increment = firebase.firestore.FieldValue.increment(2);
  const trendingRef = db
    .collection("trending")
    .doc(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );

  const linkClicked = (event, value) => {
    trendingRef.set({ [blaster.id]: increment }, { merge: true });
  };

  useEffect(() => {
    const getData = async () => {
      const resizedRef = storageRef(
        storage,
        `blasterImages/${blaster.imageArray[0]}_256x144`
      );
      getDownloadURL(resizedRef).then((url) => {
        setBlasterHero(url);
      });
    };
    getData();
  });

  const blasterURL = "./blaster?blaster=" + blaster.id;

  var fpsStr = "";
  if (blaster.fpsLow) {
    fpsStr += blaster.fpsLow;
  }
  if (blaster.fpsHigh && blaster.fpsHigh !== blaster.fpsLow) {
    fpsStr += "-" + blaster.fpsHigh;
  }
  fpsStr += " FPS";

  return (
    <Link
      href={blasterURL}
      underline="hover"
      color="black"
      onMouseDown={(e) => linkClicked(e, "kit")}
    >
      <Card className="detailHeaderGrid">
        <img
          style={{
            width: "128px",
            aspectRatio: "16/9",
            objectFit: "cover",
            gridColumn: "image",
          }}
          src={blasterHero}
          alt="Blaster"
        />
        <div className="headerSearchName">
          <h5 className="headerSearchNameText">{blaster.blasterName}</h5>
          <h6 className="headerSearchDescText">{blaster.shortDesc}</h6>
        </div>

        <h5 className="headerSearchCreator">{blaster.creator}</h5>

        <h6 className="headerSearchPropulsion">{blaster.propulsion}</h6>

        <h6 className="headerSearchFPS">{fpsStr}</h6>
      </Card>
    </Link>
  );
}
