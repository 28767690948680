import React, { useState, useReducer, useEffect } from "react";
import "../App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useSearchParams } from "react-router-dom";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { storage } from "../useFirebase";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { useNavigate } from "react-router-dom";

import caliburnIcon from "../img/caliburnIcon.png";

import HeroImg from "../HeroImg.js";

import Button from "@mui/material/Button";

import AddSidebar from "./AddSidebar";
import ImageSelector from "../ImageSelector.js";
import AddImage from "./AddImage.js";
import AddTabs from "./AddTabs.js";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import AddBlasterCard from "../AddBlasterPage/AddBlasterCard";

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const formReducer = (state, event) => {
  switch (event.type) {
    case "updateAll":
      return {
        ...state,
        ...event.object,
        id: event.id,
        released: event.released,
      };
    default:
      return {
        ...state,
        [event.name]: event.value,
      };
  }
};

export default function UpdateBlaster(props) {
  const initialData = {
    ammo: {
      full: false,
      half: false,
      rival: false,
      mega: false,
      megaXL: false,
      rockets: false,
    },
    blasterName: "",
    construction: "",
    creator: "",
    desc: "",
    diff: "",
    feed: "",
    files: "",
    filesPrice: "",
    fpsHigh: 0,
    fpsLow: 0,
    id: "",
    imageArray: [],
    kit: "",
    kitPrice: "",
    position: "",
    propulsion: "",
    released: firebase.firestore.Timestamp.fromDate(new Date()),
    rof: "",
    shortDesc: "",
    store: "",
    storePrice: "",
    videoReviews: [],
  };

  const [blasterHero, setBlasterHero] = useState(caliburnIcon);
  const [imageURL, setImageURL] = useState("");
  const [blasterData, setBlasterData] = useReducer(formReducer, initialData);
  const [currTab, setCurrTab] = React.useState(0);
  const [videoKey, setVideoKey] = React.useState("");
  const firestore = firebase.firestore();
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const blaster = searchParams.get("blaster");

  const blasterDatabase = props.pending ? "pendingBlaster" : "blaster";

  useEffect(() => {
    const getData = async () => {
      const docRef = doc(firebase.firestore(), blasterDatabase + "s", blaster);
      const docSnap = await getDoc(docRef);
      const dateData = new Date(docSnap.data().released.seconds * 1000); //Convert Timestamp to Date

      setBlasterData({
        type: "updateAll",
        object: docSnap.data(),
        released: dateData,
        id: blaster,
      });
      // setBlasterHero(docSnap.data().imageArray[0]);

      const resizedRef = storageRef(
        storage,
        `blasterImages/${docSnap.data().imageArray[0]}_1440x810`
      );
      getDownloadURL(resizedRef).then((url) => {
        setBlasterHero(url);
      });
    };
    getData();
  }, [blaster, blasterDatabase]);

  const changeTab = (event, newValue) => {
    setCurrTab(newValue);
  };

  function youtubeParser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }

  function handleChange(newValue) {
    setBlasterData({
      name: "imageArray",
      value: blasterData.imageArray.filter((item) => item !== newValue),
    });
  }

  function moveLeft(url) {
    const arrayIndex = blasterData.imageArray.findIndex(
      (element) => element === url
    );

    if (arrayIndex !== 0) {
      let array = blasterData.imageArray;
      let arrayTemp = array[arrayIndex - 1];

      array[arrayIndex - 1] = array[arrayIndex];
      array[arrayIndex] = arrayTemp;

      setBlasterData({
        name: "imageArray",
        value: array,
      });
    }
  }

  function moveRight(url) {
    const arrayIndex = blasterData.imageArray.findIndex(
      (element) => element === url
    );

    if (arrayIndex !== blasterData.imageArray.length - 1) {
      let array = blasterData.imageArray;
      let arrayTemp = array[arrayIndex + 1];

      array[arrayIndex + 1] = array[arrayIndex];
      array[arrayIndex] = arrayTemp;

      setBlasterData({
        name: "imageArray",
        value: array,
      });
    }
  }

  const addVideoUrl = () => {
    if (videoKey !== "") {
      setVideoKey("");
      setBlasterData({
        name: "videoReviews",
        value: [...blasterData.videoReviews, youtubeParser(videoKey)],
      });
    }
  };

  const addURL = (url) => {
    if (url !== "") {
      setImageURL("");

      setBlasterData({
        name: "imageArray",
        value: [...blasterData.imageArray, url],
      });
    }
  };

  function handleVideoRemove(newValue) {
    console.timeLog(blasterData);
    setBlasterData({
      name: "videoReviews",
      value: blasterData.videoReviews.filter((item) => item !== newValue),
    });
  }

  const handleChangeForm = (event) => {
    setBlasterData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const ammoChange = (ammoVal) => {
    setBlasterData({
      name: "ammo",
      value: ammoVal,
    });
  };

  const dateChange = (dateVal) => {
    setBlasterData({
      name: "released",
      value: dateVal.format("MM/DD/YYYY"),
    });
  };

  const changeDesc = (desc) => {
    setBlasterData({
      name: "desc",
      value: desc,
    });
  };

  function changeHero(newValue) {
    setBlasterHero(newValue.replace("_256x144", "_1440x810"));
  }

  const submitUpdate = async (e) => {
    if (blasterData.fpsHigh === 0) {
      blasterData.fpsHigh = blasterData.fpsLow;
    }

    blasterData.fpsHigh = Number(blasterData.fpsHigh);
    blasterData.fpsLow = Number(blasterData.fpsLow);

    if (blasterData.kit) {
      blasterData.kitBool = true;
    } else {
      blasterData.kitBool = false;
    }
    if (blasterData.store) {
      blasterData.storeBool = true;
    } else {
      blasterData.storeBool = false;
    }
    if (blasterData.files) {
      blasterData.filesBool = true;
    } else {
      blasterData.filesBool = false;
    }

    const dateData = new Date(blasterData.released);

    await firestore
      .collection("blasterUpdates")
      .doc(blasterData.id)
      .set({
        ...blasterData,
        released: firebase.firestore.Timestamp.fromDate(dateData),
      });

    alert("Update sent to Moderators!");

    setTimeout(navigate("/blaster?blaster=" + blasterData.id), 3000);
  };

  const submitBlaster = async (e) => {
    if (blasterData.fpsHigh === 0) {
      blasterData.fpsHigh = blasterData.fpsLow;
    }

    blasterData.fpsHigh = Number(blasterData.fpsHigh);
    blasterData.fpsLow = Number(blasterData.fpsLow);

    if (blasterData.kit) {
      blasterData.kitBool = true;
    } else {
      blasterData.kitBool = false;
    }
    if (blasterData.store) {
      blasterData.storeBool = true;
    } else {
      blasterData.storeBool = false;
    }
    if (blasterData.files) {
      blasterData.filesBool = true;
    } else {
      blasterData.filesBool = false;
    }

    const dateData = new Date(blasterData.released);

    await firestore
      .collection("blasters")
      .doc(blasterData.id)
      .update({
        ...blasterData,
        released: firebase.firestore.Timestamp.fromDate(dateData),
      });

    alert("Blaster Updated!");
  };

  const deleteBlaster = async (e) => {
    await deleteDoc(doc(firestore, "pendingBlasters", blasterData.id));

    alert("Blaster Deleted!");

    setTimeout(navigate("/searchPending"), 3000);
  };

  const saveBlaster = async (e) => {
    if (blasterData.fpsHigh === 0) {
      blasterData.fpsHigh = blasterData.fpsLow;
    }

    blasterData.fpsHigh = Number(blasterData.fpsHigh);
    blasterData.fpsLow = Number(blasterData.fpsLow);

    if (blasterData.kit) {
      blasterData.kitBool = true;
    } else {
      blasterData.kitBool = false;
    }
    if (blasterData.store) {
      blasterData.storeBool = true;
    } else {
      blasterData.storeBool = false;
    }
    if (blasterData.files) {
      blasterData.filesBool = true;
    } else {
      blasterData.filesBool = false;
    }

    const dateData = new Date(blasterData.released);

    await firestore
      .collection("pendingBlasters")
      .doc(blasterData.id)
      .update({
        ...blasterData,
        released: firebase.firestore.Timestamp.fromDate(dateData),
      });

    alert("Blaster saved to Pending!");
  };

  const addBlaster = async (e) => {
    if (blasterData.fpsHigh === 0) {
      blasterData.fpsHigh = blasterData.fpsLow;
    }

    blasterData.fpsHigh = Number(blasterData.fpsHigh);
    blasterData.fpsLow = Number(blasterData.fpsLow);

    if (blasterData.kit) {
      blasterData.kitBool = true;
    } else {
      blasterData.kitBool = false;
    }
    if (blasterData.store) {
      blasterData.storeBool = true;
    } else {
      blasterData.storeBool = false;
    }
    if (blasterData.files) {
      blasterData.filesBool = true;
    } else {
      blasterData.filesBool = false;
    }

    const dateData = new Date(blasterData.released);

    await firestore
      .collection("blasters")
      .doc(blasterData.id)
      .set({
        ...blasterData,
        released: firebase.firestore.Timestamp.fromDate(dateData),
      });
    await deleteDoc(doc(firestore, "pendingBlasters", blasterData.id));

    alert("Blaster added to Site!");

    setTimeout(navigate("/searchPending"), 3000);
  };

  return (
    <div className="App">
      <Box className="gridMain" sx={{ justifyContent: "center" }}>
        <Box className="imgBox">
          {/* Main Image */}

          <div className="imageContainer">
            <div className="addImage">
              <ImageSelector
                imageArray={blasterData.imageArray}
                onChange={changeHero}
              />
              <HeroImg blasterImage={blasterHero} />
            </div>
          </div>

          {/* Image Add Box */}

          <AddImage
            changeHero={changeHero}
            imageURL={imageURL}
            setImageURL={setImageURL}
            imageArray={blasterData.imageArray}
            addURL={addURL}
            handleChange={handleChange}
            moveRight={moveRight}
            moveLeft={moveLeft}
          ></AddImage>
        </Box>

        {/* Tab Box */}

        <AddTabs
          blasterData={blasterData}
          changeDesc={changeDesc}
          blasterId={blasterData.id}
          videoReviews={blasterData.videoReviews}
          currTab={currTab}
          videoKey={videoKey}
          setVideoKey={setVideoKey}
          changeTab={changeTab}
          addVideoUrl={addVideoUrl}
          handleVideoRemove={handleVideoRemove}
        ></AddTabs>

        {/* Sidebar */}

        <AddSidebar
          blasterData={blasterData}
          onChange={handleChangeForm}
          ammoChange={ammoChange}
          dateChange={dateChange}
        />
      </Box>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: "335px",
          marginTop: "30px",
        }}
      >
        <Card
          sx={{
            width: "25%",
            minWidth: "325px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <h1 style={{ marginBottom: "0px" }}>Example Card:</h1>
          <h4 style={{ marginTop: "4px", width: "70%", textAlign: "center" }}>
          </h4>
          <AddBlasterCard
            blasterData={blasterData}
            source={props.pending ? "pending" : ""}
          />
        </Card>
      </div>

      {/* Submit Button */}

      <div style={{ display: "flex", justifyContent: "center" }}>
        {props.role === "Admin" || props.role === "Moderator" ? (
          <>
            {props.pending ? (
              <>
                <Button
                  size="large"
                  variant="contained"
                  color="success"
                  style={{}}
                  onClick={addBlaster}
                  className="updateButton"
                >
                  Update and Publish
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  style={{}}
                  onClick={saveBlaster}
                  className="updateButton"
                >
                  Save, but don't Publish
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="error"
                  style={{}}
                  onClick={deleteBlaster}
                  className="updateButton"
                >
                  Delete, and don't Publish
                </Button>
              </>
            ) : (
              <>
                {" "}
                {props.request ? (
                  ""
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    style={{}}
                    onClick={submitBlaster}
                    className="updateButton"
                  >
                    Update
                  </Button>
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
        {props.request ? (
          <Button
            size="large"
            variant="contained"
            style={{}}
            onClick={submitUpdate}
            className="updateButton"
          >
            Send Update to Moderators
          </Button>
        ) : (
          ""
        )}
        <Button
          size="large"
          variant="contained"
          style={{}}
          href={
            props.pending
              ? "./searchPending"
              : "./blaster?blaster=" + blasterData.id
          }
          className="updateButton"
        >
          Cancel and Return
        </Button>
      </div>
    </div>
  );
}
