import React from "react";
import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { NavLink } from "react-router-dom";

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL:process.env.REACT_APP_DATABASE_URL
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default function Header(props) {

  // const [open, setOpen] = React.useState(false);
  // const anchorRef = React.useRef(null);

  // // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  return (
    <header className="App-header" style={{display:"flex", justifyContent: "center"}}>
      <div className="footerContainer">
        <NavLink className="title" to="/">
          BlasterBrowser
        </NavLink>
        <p style={{ fontSize: "12px", margin: "4px" }}>BlasterBrowser uses affliate links to support itself. Links for blasters may give us a small kick back.</p>

        <div style={{ display: "flex", flexDirection:"row" }}>
          <a href="/search" style={{ color: "inherit" }} ><h6 style={{ color: "white", margin: "4px" }}>Search</h6></a>
          <h6 style={{color:"grey", fontWeight: "400", margin: "4px 20px"}}>|</h6>
          <a href="/add" style={{ color: "inherit" }} ><h6 style={{ color: "white", margin: "4px" }}>Add Blaster</h6></a>
          <h6 style={{color:"grey", fontWeight: "400", margin: "4px 20px"}}>|</h6>
          <a href="/features" style={{ color: "inherit" }} ><h6 style={{ color: "white", margin: "4px" }}>Request a Feature</h6></a>
        </div>
      </div>
    </header>
  );
}

